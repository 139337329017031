// Css
import '../../css/scss/users/login.scss'

// Js
import Vue from 'vue'

Vue.component('login', {
  data() {
    let data = this.$parent.getData()
    return data
  },
  mounted() {

  },
  methods: {
    onEmailKeyUp(event) {
      let inputValue = event.currentTarget.value
      event.currentTarget.value = inputValue.toLowerCase()
    }
  }
})
